html, body {
  height: 100%; }

body {
  font-family: "Abel", serif;
  font-size: 16px;
  background-image: url("../img/patterns/1.png");
  background-repeat: repeat; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Stardos Stencil", Arial, sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  margin: 10px 0; }

@media (min-width: 560px) {
  h1 {
    font-size: 65px; } }

@media (min-width: 560px) {
  h2 {
    font-size: 50px; } }

hr {
  max-width: 100px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  height: 2px;
  margin: 15px auto; }
  hr.colored {
    border-color: #a94442; }
  @media (min-width: 768px) {
    hr {
      max-width: 200px;
      margin: 20px auto; } }

.pagination {
  border-radius: 0; }
  .pagination > li > a:hover,
  .pagination > li > span:hover, .pagination > .active > a,
  .pagination > .active > span,
  .pagination > .active > a:hover,
  .pagination > .active > span:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span:focus {
    color: #fff;
    background-color: #cd6666;
    border-color: #cd6666; }
  .pagination > li > a,
  .pagination > li > span {
    color: #a94442;
    background-color: #fff; }
  .pagination > li:first-child > a,
  .pagination > li:first-child > span {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }

.navbar {
  border-radius: 0;
  margin-bottom: 0; }

.navbar-default {
  background-color: #fff;
  border: none; }
  .navbar-default .navbar-nav > li a,
  .navbar-default .navbar-nav > li a:hover,
  .navbar-default .navbar-nav > li a:focus,
  .navbar-default .navbar-nav > li a:active,
  .navbar-default .navbar-nav > li a:visited {
    outline: none;
    font-weight: bold;
    font-size: 18px;
    color: #a94442; }
  .navbar-default .navbar-header img {
    height: 65px; }
    @media (max-width: 795px) {
      .navbar-default .navbar-header img {
        padding-left: 10px;
        height: 50px; } }
  .navbar-default .navbar-header a,
  .navbar-default .navbar-header a:hover,
  .navbar-default .navbar-header a:focus,
  .navbar-default .navbar-header a:active,
  .navbar-default .navbar-header a:visited {
    outline: none; }
  .navbar-default .navbar-nav {
    margin: 0 -15px; }
    .navbar-default .navbar-nav.extra {
      margin: 7.5px -15px;
      float: left; }
    @media (min-width: 768px) {
      .navbar-default .navbar-nav {
        margin: 7.5px -15px; } }
  .navbar-default .navbar-nav > li.active a,
  .navbar-default .navbar-nav > li.active a:hover,
  .navbar-default .navbar-nav > li.active a:focus {
    background-color: #fff;
    color: #a94442; }
  @media (min-width: 768px) {
    .navbar-default .navbar-nav > li.active:after {
      content: '\f005';
      font-family: FontAwesome;
      position: absolute;
      top: 75%;
      left: 50%;
      margin: 0 0 0 -5px;
      font-size: 16px;
      color: #a94442; } }
  .navbar-default .navbar-brand {
    font-family: "Stardos Stencil", Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    color: #000;
    opacity: 1;
    text-decoration: none;
    height: 0;
    transition: all 0.5s;
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 12px 15px; }
  .navbar-default .navbar-toggle {
    margin-top: 12px;
    margin-bottom: 12px; }
    .navbar-default .navbar-toggle .icon-bar {
      background-color: #a94442; }
  .navbar-default .navbar-collapse,
  .navbar-default .navbar-form {
    border-color: transparent; }

.boxed .navbar.navbar-fixed-top {
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.25);
  margin-bottom: 0; }
  @media (min-width: 768px) {
    .boxed .navbar.navbar-fixed-top {
      transition: padding 0.5s; } }
  @media (min-width: 1200px) {
    .boxed .navbar.navbar-fixed-top {
      max-width: 1170px;
      margin-left: auto;
      margin-right: auto; } }

.links {
  position: absolute;
  right: 0;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.25), -3px 1px 3px -2px rgba(0, 0, 0, 0.25);
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px; }
  .links .list-inline {
    margin: 5px 10px; }

.testimonials {
  text-align: center; }
  .testimonials .testimonials-carousel .item {
    padding: 0; }
    .testimonials .testimonials-carousel .item p.quote {
      margin-bottom: 30px; }
    .testimonials .testimonials-carousel .item .testimonial-info {
      margin-bottom: 30px; }
      .testimonials .testimonials-carousel .item .testimonial-info .testimonial-img {
        display: inline-block;
        height: 50px;
        width: 50px;
        margin-right: 10px; }
      .testimonials .testimonials-carousel .item .testimonial-info .testimonial-author {
        display: inline-block; }
        .testimonials .testimonials-carousel .item .testimonial-info .testimonial-author p {
          margin: 0; }
        .testimonials .testimonials-carousel .item .testimonial-info .testimonial-author .stars {
          color: #f1c40f; }
        .testimonials .testimonials-carousel .item .testimonial-info .testimonial-author .name {
          font-family: "Stardos Stencil", Arial, sans-serif;
          font-weight: 900;
          text-transform: uppercase; }
    @media (min-width: 768px) {
      .testimonials .testimonials-carousel .item {
        padding: 0 100px; } }
  .testimonials .testimonials-carousel .owl-controls {
    margin-top: 0; }
    .testimonials .testimonials-carousel .owl-controls .owl-prev, .testimonials .testimonials-carousel .owl-controls .owl-next {
      display: none;
      text-align: center;
      position: absolute;
      height: 60px;
      width: 40px;
      border-radius: 0 !important;
      line-height: 50px;
      font-size: 50px !important;
      margin: auto 0 !important;
      top: 0;
      bottom: 0;
      background-color: transparent !important;
      color: #a94442 !important;
      transition: all 0.5s;
      opacity: .75; }
    .testimonials .testimonials-carousel .owl-controls .owl-page span {
      background: rgba(34, 34, 34, 0.5); }
    .testimonials .testimonials-carousel .owl-controls .owl-prev {
      left: 15px; }
      @media (min-width: 768px) {
        .testimonials .testimonials-carousel .owl-controls .owl-prev {
          display: block; } }
    .testimonials .testimonials-carousel .owl-controls .owl-next {
      right: 15px; }
      @media (min-width: 768px) {
        .testimonials .testimonials-carousel .owl-controls .owl-next {
          display: block; } }

.alert {
  border-radius: 0;
  z-index: 1000; }

.alert-success {
  background-color: #00b64e;
  color: #fff;
  box-shadow: 0 0 5px 0 rgba(107, 107, 107, 0.75); }

.alert-danger {
  background-color: #cd6666;
  color: #fff;
  box-shadow: 0 0 5px 0 rgba(107, 107, 107, 0.75); }

.flash {
  position: absolute;
  right: 10px;
  top: 20px; }

.has-error .help-block {
  color: #fff;
  background-color: #cd6666;
  padding: 3px 10px;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.25); }

.btn {
  border-radius: 0;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px; }
  .btn:focus, .btn:active, .btn.active {
    outline: none; }

.btn-danger {
  background-color: #cd6666; }
  .btn-danger:hover {
    background-color: #a94442; }

.btn-outline-dark {
  color: #333333;
  border: 1px solid #333333;
  background: transparent;
  transition: all 0.5s; }
  .btn-outline-dark:hover {
    color: #fff;
    background: #cd6666;
    border-color: #cd6666;
    outline: none; }

.btn-social-icon {
  position: relative;
  height: 34px;
  width: 34px;
  padding: 0;
  color: #000;
  background-color: #fff;
  border-color: #c2c2c2; }
  .btn-social-icon:first-child {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    line-height: 32px;
    font-size: 20px; }

.twitter {
  color: #409ddb; }

.linkedin {
  color: #14538a; }

.email {
  color: #a94442; }

form.btnDelete {
  display: inline-block; }

.pricing .pricing-item {
  text-align: center;
  background: #fff;
  color: #000;
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0 0 15px 0 rgba(34, 34, 34, 0.75);
  z-index: 1; }
  .pricing .pricing-item ul {
    text-align: left;
    padding-top: 20px;
    padding-bottom: 20px; }
    .pricing .pricing-item ul > li {
      padding-bottom: 10px; }
  .pricing .pricing-item h3 {
    margin: 0; }
  .pricing .pricing-item hr {
    max-width: 100px; }
  .pricing .pricing-item .price {
    margin-bottom: 20px; }
    .pricing .pricing-item .price .number {
      font-weight: 900;
      font-size: 50px; }
      .pricing .pricing-item .price .number sup {
        font-size: 30px; }
  @media (min-width: 992px) {
    .pricing .pricing-item {
      margin-bottom: 0; }
      .pricing .pricing-item.featured-first {
        margin-right: -15px;
        margin-top: 15px; }
      .pricing .pricing-item.featured {
        position: absolute;
        width: 100%;
        left: 0;
        margin: 0;
        padding: 45px 30px;
        z-index: 3; }
      .pricing .pricing-item.featured-last {
        margin-left: -15px;
        margin-top: 15px; }
        .pricing .pricing-item.featured-last p {
          font-size: inherit;
          padding: 5px 10px 0 10px; } }

.wrapper-auth {
  width: 100%;
  height: 100%;
  padding-top: 200px; }
  .wrapper-auth .panel.panel-default {
    border-radius: 0;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.25); }
    .wrapper-auth .panel.panel-default .panel-heading {
      background-color: #c0605e;
      color: #fff;
      text-align: center;
      font-size: 22px;
      border-top-right-radius: 0;
      border-top-left-radius: 0; }

.m25 {
  margin-top: 25px;
  margin-bottom: 25px; }

.m50 {
  margin-top: 50px;
  margin-bottom: 50px; }

.mt25 {
  margin-top: 25px; }

.mt50 {
  margin-top: 50px; }

.mb25 {
  margin-bottom: 25px; }

.mb50 {
  margin-bottom: 50px; }

.p25 {
  padding-top: 25px;
  padding-bottom: 25px; }

.p50 {
  padding-top: 50px;
  padding-bottom: 50px; }

.pt25 {
  padding-top: 25px; }

.pt50 {
  padding-top: 50px; }

.pb25 {
  padding-bottom: 25px; }

.pb50 {
  padding-bottom: 50px; }

.padding10 {
  padding: 10px; }

.ml25 {
  margin-left: 25px; }

.performances {
  font-size: 14px; }
  @media (min-width: 768px) {
    .performances {
      margin-left: 25px; } }

section {
  padding: 100px 0 50px 0;
  background-color: #fff;
  box-shadow: inset 0 3px 3px -2px rgba(0, 0, 0, 0.25); }
  section:nth-of-type(even) {
    background-color: #f5f5f5; }

article {
  text-align: justify; }

address {
  position: absolute;
  width: 100%;
  bottom: 15px;
  left: 0;
  opacity: 0.8;
  padding: 1em;
  color: #fff;
  text-align: center;
  background-color: #a94442; }

main {
  background-color: #f5f5f5; }

header .intro-content {
  color: #fff;
  text-align: center;
  position: relative; }

header .scroll-down {
  position: absolute;
  width: 100%;
  bottom: 20px;
  text-align: center; }
  header .scroll-down .btn {
    height: 50px;
    width: 50px;
    border: 2px solid #fff;
    border-radius: 100%;
    line-height: 50px;
    padding: 0;
    letter-spacing: normal;
    color: #fff;
    font-size: 30px;
    transition: all 0.5s; }
    header .scroll-down .btn:hover {
      color: rgba(255, 255, 255, 0.5);
      border-color: rgba(255, 255, 255, 0.5);
      outline: none; }

@media (min-width: 768px) {
  header .brand-name-subtext {
    font-size: 24px;
    line-height: 30px; } }

footer {
  background-color: #fff;
  box-shadow: inset 0 3px 3px -2px rgba(0, 0, 0, 0.25); }
  footer p {
    margin-bottom: 0; }

div#nav {
  padding: 10px 15px; }

textarea {
  resize: vertical; }

.modal {
  overflow-y: auto; }

.modal-open {
  overflow: auto; }
  .modal-open[style] {
    padding-right: 0 !important; }

.overlay {
  position: relative;
  display: block;
  width: 100%;
  color: #fff;
  background: no-repeat fixed center center;
  background-size: cover; }
  .overlay:before {
    position: absolute;
    content: " ";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); }

.extra > li {
  position: relative;
  display: inline-block; }
  .extra > li:first-child {
    padding-left: 15px; }
  .extra > li a {
    color: #a94442 !important;
    font-size: 16px !important; }

option[selected] {
  display: none; }

dl {
  margin-bottom: 0; }
  dl dt ~ dt {
    margin-top: 5px; }
  dl dd {
    margin-bottom: 5px;
    margin-top: 5px; }

.row.no-pad {
  margin-right: 0;
  margin-left: 0; }
  .row.no-pad > [class*='col-'] {
    padding-right: 0;
    padding-left: 0; }

a {
  color: #a94442;
  transition: all 0.5s; }
  a:hover, a:focus {
    color: inherit;
    text-decoration: none; }

p {
  line-height: 1.5; }

#intro {
  padding: 200px 0; }

#quote {
  background-image: url("../img/backgrounds/bg-quote.jpg"); }
  #quote .row {
    padding: 75px 0; }
  #quote.overlay:before {
    background: rgba(0, 0, 0, 0.7); }
  #quote span.quote {
    display: block;
    font-size: 40px;
    line-height: 42px; }

#stats {
  background-image: url("../img/backgrounds/bg7.jpg"); }
  #stats .row {
    padding: 75px 0; }
  #stats .stat .stat-icon {
    margin-bottom: 10px; }
    #stats .stat .stat-icon i {
      margin-right: 10px; }

.human, #mailchimp-errors .alert, #contact-errors .alert {
  display: none; }

.panel-group {
  margin-bottom: 0; }
  .panel-group .panel {
    border-radius: 0; }

#tarifs .panel-default {
  border-radius: 0; }
  #tarifs .panel-default .panel-heading {
    padding: 0;
    border-radius: 0;
    color: #a94442;
    background-color: #fff; }
    #tarifs .panel-default .panel-heading a {
      position: relative;
      display: block;
      padding: 10px; }
      #tarifs .panel-default .panel-heading a:after {
        font-family: "FontAwesome";
        content: "\f068";
        position: absolute;
        right: 15px;
        font-size: 14px;
        font-weight: 300;
        top: 50%;
        line-height: 1;
        margin-top: -7px; }
      #tarifs .panel-default .panel-heading a.collapsed:after {
        content: "\f067"; }
    #tarifs .panel-default .panel-heading h4 small {
      color: #a94442;
      font-family: "Abel", serif !important; }

.table {
  margin-bottom: 0; }

.about-content {
  padding: 20px;
  margin-bottom: 10px;
  box-shadow: 0 0 5px 0 rgba(107, 107, 107, 0.75); }
  .about-content a {
    margin-top: 15px; }

.form-control {
  font-size: 16px;
  border-radius: 0; }

@media (min-width: 768px) {
  .thumbnail img {
    height: 200px; } }

thead {
  background-color: #fff; }

#team .team-member {
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.25);
  position: relative; }
  #team .team-member .member-pic {
    border: 5px solid #fff;
    border-radius: 3px; }
    #team .team-member .member-pic img {
      width: 100%; }
  #team .team-member .member-details {
    padding-bottom: 5px;
    width: 85%;
    background: #fff;
    z-index: 1;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.25);
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    margin-bottom: -25px;
    margin-left: auto;
    margin-right: auto; }

#team .list-inline {
  position: relative;
  transition: all ease 0.55s; }

.lead {
  font-size: 18px; }

.valign {
  display: table-cell;
  vertical-align: middle; }

@media (min-width: 768px) {
  .content-row {
    margin: 30px 20px 0; } }

.wrapper {
  overflow: hidden;
  height: auto;
  padding-top: 75px;
  position: relative;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5; }
  @media (min-width: 1200px) {
    body.boxed .wrapper {
      max-width: 1170px;
      margin-left: auto;
      margin-right: auto; } }

#gmaps {
  height: 350px; }

#googleMaps {
  padding: 1px 0; }
  #googleMaps .row {
    position: relative; }

/******************************************/
.btn-file {
  overflow: hidden;
  position: relative;
  vertical-align: middle; }

.btn-file > input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 100%;
  width: 100%;
  direction: ltr;
  cursor: pointer; }

.fileinput {
  margin-bottom: 9px;
  display: inline-block; }

.fileinput .form-control {
  padding-top: 7px;
  padding-bottom: 5px;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  cursor: text; }

.fileinput .thumbnail {
  overflow: hidden;
  display: inline-block;
  margin-bottom: 5px;
  vertical-align: middle;
  text-align: center; }

.fileinput .thumbnail > img {
  max-height: 100%; }

.fileinput .btn {
  vertical-align: middle; }

.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
  display: none; }

.fileinput-inline .fileinput-controls {
  display: inline; }

.fileinput-filename {
  vertical-align: middle;
  display: inline-block;
  overflow: hidden; }

.form-control .fileinput-filename {
  vertical-align: bottom; }

.fileinput.input-group {
  display: table; }

.fileinput.input-group > * {
  position: relative;
  z-index: 2; }

.fileinput.input-group > .btn-file {
  z-index: 1; }

.form-group.has-warning .fileinput .fileinput-preview {
  color: #8a6d3b; }

.form-group.has-warning .fileinput .thumbnail {
  border-color: #faebcc; }

.form-group.has-error .fileinput .fileinput-preview {
  color: #a94442; }

.form-group.has-error .fileinput .thumbnail {
  border-color: #ebccd1; }

.form-group.has-success .fileinput .fileinput-preview {
  color: #3c763d; }

.form-group.has-success .fileinput .thumbnail {
  border-color: #d6e9c6; }

.input-group-addon:not(:first-child) {
  border-left: 0; }

/*# sourceMappingURL=app.css.map */
